<template>
  <div class="our-courses-container">
    <div class="our-courses-badge">From our courses</div>
    <div class="our-courses-title">
      Explore our comprehensive language Courses
    </div>
    <div class="our-courses-subtitle">
      Courses designed for every learner, from beginner to advanced
    </div>
    <recordListComponent
      class="our-courses-record-list"
      :records="getCourseList"
    />
    <div class="row">
      <div class="col-lg-6">
        <md-filled-tonal-button
          class="get-started-button"
          @click="
            $router.push(
              $route.path.includes('for-individuals')
                ? '/book-demo'
                : '/try-comligo-for-free'
            )
          "
        >
          Book Your Free Trial Now
          <i class="mdi mdi-arrow-right"></i>
        </md-filled-tonal-button>
      </div>
      <div class="col-lg-6">
        <md-outlined-button
          class="view-our-courses-button"
          @click="$router.push('/spanish-courses-overview')"
        >
          View our courses
          <i class="mdi mdi-arrow-right"></i>
        </md-outlined-button>
      </div>
    </div>
  </div>
</template>

<script>
import recordListComponent from "./recordListComponentCourses.vue";
import { mapGetters } from "vuex";

export default {
  name: "OurCoursesComponent",
  components: {
    recordListComponent,
  },
  computed: {
    ...mapGetters(["getCourseList"]),
  },
};
</script>

<style></style>
